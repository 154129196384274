<template>
  <div class="bt-transaksi pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-cash-100</v-icon>
      </div>
      Pencairan/ Akad
    </h6>
    <v-row class="mt-5">
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="form.data.cm_code"
          :items="opt.rembug"
          @change="getAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Anggota</label>
        <v-select
          color="black"
          v-model="form.data.cif_no"
          :items="opt.anggota"
          @change="setProfile()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-5">
        <label for="">Pembiayaan</label>
        <v-select
          color="black"
          v-model="form.data.registration_no"
          :items="opt.pengajuan"
          @change=" setPengajuan()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
    </v-row>
    <v-card
      class="white elevation-3 rounded-lg pa-3 mb-3 mt-5"
    >
      <h6 class="text-h6 font-weight-bold mb-4">Pencairan</h6>
      <v-row>
        <v-col cols="7" class="pb-0">
          <label class="black--text">Pokok</label>
        </v-col>
        <v-col cols="5">
          <v-text-field
            color="black"
            autocomplete="off"
            hide-details
            solo
            dense
            disabled
            v-model="form.data.pokok"
            v-mask="thousandMask"
            class="justify-end text-right"
          />
        </v-col>
        <v-col cols="7" class="pb-0">
          <label class="black--text">Biaya Administrasi</label>
        </v-col>
        <v-col cols="5">
          <v-text-field
            color="black"
            autocomplete="off"
            hide-details
            solo
            dense
            disabled
            v-model="form.data.biaya_administrasi"
            v-mask="thousandMask"
            class="justify-end text-right"
          />
        </v-col>
        <v-col cols="7" class="pb-0">
          <label class="black--text">Biaya Asuransi</label>
        </v-col>
        <v-col cols="5">
          <v-text-field
            color="black"
            autocomplete="off"
            hide-details
            solo
            dense
            disabled
            v-model="form.data.biaya_asuransi_jiwa"
            v-mask="thousandMask"
            class="justify-end text-right"
          />
        </v-col>
        <!-- <v-col cols="7" class="pb-0">
          <label class="black--text">Tabungan 4%</label>
        </v-col>
        <v-col cols="5">
          <v-text-field
            color="black"
            autocomplete="off"
            hide-details
            solo
            dense
            disabled
            v-model="form.data.tabungan_persen"
            v-mask="thousandMask"
            class="justify-end text-right"
          />
        </v-col> -->
        <v-col cols="7" class="pb-0">
          <label class="black--text">Dana Kegiatan</label>
        </v-col>
        <v-col cols="5">
          <v-text-field
            color="black"
            autocomplete="off"
            hide-details
            solo
            dense
            disabled
            v-model="form.data.dana_kebajikan"
            v-mask="thousandMask"
            class="justify-end text-right"
          />
        </v-col>
      </v-row>
    </v-card>
    <div class="d-flex justify-center mt-4 pt-4">
      <v-btn class="orange lighten-1 white--text mx-2" @click="$router.push(`/pembiayaan`)">Kembali</v-btn>
      <v-btn class="orange lighten-1 white--text mx-2" @click="prosesPencairan()"> Simpan </v-btn>
    </div>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
export default {
  name: "KeangotaanKeluar",
  components: {
  },
  data() {
    return {
      profile: Object,
      form: {
        data: {
          cif_no: null,
          cm_code: null,
          fa_code: null,
          registration_no: null,
          pokok: 0,
          biaya_administrasi: 0,
          biaya_asuransi_jiwa: 0,
          dana_kebajikan: 0
        }
      },
      opt: {
        alasan: [
          {value: 1, text: "Meninggal"},
          {value: 2, text: "Karakter"},
          {value: 3, text: "Pindah Lembaga Lain"},
          {value: 4, text: "Tidak diijinkan Suami"},
          {value: 5, text: "Saving Kurang"},
          {value: 6, text: "Kondisi Keluarga"},
          {value: 7, text: "Pindah Alamat"},
          {value: 8, text: "Tidak Setuju Keputusan Lembaga"},
          {value: 9, text: "Usia / Jompo"},
          {value: 10, text: "Sakit"},
          {value: 11, text: "Majelis Bubar"},
          {value: 12, text: "Tidak Punya Waktu"},
          {value: 13, text: "Kerja"},
          {value: 14, text: "Cerai"},
          {value: 15, text: "Pembiayaan Bermasalah"},
          {value: 16, text: "Usaha Sdh Berkembang"},
          {value: 17, text: "Tidak Mau Kumpulan"},
          {value: 18, text: "Batal Pembiayaan (Anggota baru)"},
          {value: 19, text: "Migrasi Anggota Individu"}
        ],
        rembug: [],
        anggota: [],
        pengajuan: []
      },
      alert: {
        show: false,
        msg: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.rembug = [];
          if(data){
            data.map((item) => {
              let opt = {
                value: item.cm_code,
                text: item.cm_name,
                data: item,
              };
              this.opt.rembug.push(opt);
            });
          }
        } else {
          this.notif('Gagal mendapatkan data Rembug')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.form.data.cm_code);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.anggota = [];
          data.map((item) => {
            let opt = {
              value: item.cif_no,
              text: item.nama,
              data: item,
            };
            this.opt.anggota.push(opt);
          });
        } else {
          this.notif('Gagal mendapatkan data Anggota')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    async getPengajuan() {
      let payload = new FormData();
      payload.append("cif_no", this.form.data.cif_no);
      try {
        let req = await services.pengajuanAnggota(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.pengajuan = [];
          data.map((item) => {
            let opt = {
              value: item.registration_no,
              text: `${item.registration_no} - Rp ${this.thousand(item.amount)}`,
              data: item,
            };
            this.opt.pengajuan.push(opt);
          });
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    setPengajuan() {

    },
    setProfile() {
      let profile = this.opt.anggota.find(
        (item) => item.value == this.form.data.cif_no
      ).data;
      this.profile = profile
      this.getPengajuan()
    },
    countHutang() {
      if(this.form.data.flag_saldo_margin){
        this.form.data.saldo_hutang = Number(this.form.data.saldo_pembiayaan) + Number(this.form.data.saldo_margin)
      } else {
        this.form.data.saldo_hutang = this.form.data.saldo_pembiayaan
      }
      if(this.form.data.potongan_pembiayaan && Number(this.form.data.potongan_pembiayaan) > 0){
        this.form.data.saldo_hutang = Number(this.form.data.saldo_hutang) - Number(this.form.data.potongan_pembiayaan)
      }
      this.form.data.setoran_tambahan = Number(this.form.data.saldo_simpanan) - Number(this.form.data.saldo_hutang)
    },
    async prosesPencairan() {
      // let payload = new FormData()
      // let payloadData = {...this.form.data}
      // payloadData.flag_saldo_margin = (payloadData.flag_saldo_margin) ? 1 : 2
      // console.log(payloadData)
      // for(let key in payloadData){
      //   payload.append(key,payloadData[key])
      // }
      // try {
      //   let req = await services.registrasiAnggotaKeluar(payload, this.user.token);
      //   let {data,msg,status} = req.data
      //   console.log(req.data)
      //   if (req.status === 200 && status) {
          this.notif('Pencairan/ Akad berhasil disimpan')
          setTimeout(() => {
            this.$router.push(`/pembiayaan`);
          },1000)
      //   } else {
      //     this.notif(msg)
      //   }
      // } catch (error) {
      //   this.notif(error)
      // }
    },
    generateDate(){
      var dateObj = new Date();
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
      var day = String(dateObj.getUTCDate()).padStart(2, '0')
      var year = dateObj.getUTCFullYear();
      var hours = dateObj.getHours()
      var minutes = dateObj.getMinutes()
      var seconds = dateObj.getSeconds()
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notif(msg) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
